<template>
	<div class="wenda-wd">
		<div class="wenda-wda" :class="wend">
			<!-- 头部-搜索框 我要提问  头 -->
			<div class="wenda-wdb">
				<div class="wenda-wdbd">
					<div class="wenda-wdc">
						<el-input placeholder="试试快速检索，看看你的问题是否已经有人询问过？" v-model="input3" class="input-with-select">
							<el-button slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</div>
					<div class="">
						<a href="" class="wenda-wdd">
							<img src="../../assets/youjibi.png" class="wenda-wde">
							<div class="">我要提问</div>
						</a>
					</div>
				</div>
				<div class="wenda-wdbd">
					<div class="wenda-wdba">
						<a href="">查看我的回答</a>
					</div>
					<div class="wenda-wdbb">
						<img src="../../assets/daanb.png" class="wenda-wdbc">
						<img src="../../assets/daan.jpg" class="wenda-wdbe">
					</div>
				</div>
			</div>
			<!-- 头部-搜索框 我要提问  尾 -->
		</div>

		<div class="wenda-wdf">
			<div class="wenda-wdg">
				<div class="wenda-wdi">
					<!-- 问答-最新发表 热门游记 精品游记选项卡 -->
					<div class="wenda-wdj">
						<div class="wenda-wdk" v-for="(item,index) in essay" :key="index">
							<div class="" :class="[essaya==index?'wenda-wdl':'wenda-wdll']" @click="btn(index)">
								{{item.answers}}</div>
							<div class="wenda-wdm">{{item.answer}}</div>
						</div>
					</div>
				</div>
				<div class="" v-if="essaya==0">
					<!-- 问答-最新发表 选项卡内容 -->
					<div class="wenda-wdn" v-for="item in [1,2,4,5,6,7]" :key="item">
						<div class="">
							<p class="wenda-wdo">
								<a href="" class="wenda-wdoo">你好，我查了一下上海到大阪的特价机票，后面写着需候补，是什么意思？请帮我解答一下，谢谢 </a>
							</p>
							<div class="wenda-wdp">发表于2014年11月12日 10:50</div>
							<div class="wenda-wdq">国际机票提前多长时间订最好</div>
							<div class="wenda-wdq">{{item}}</div>
						</div>
						<div class="wenda-wdr">
							<a href="" class="wenda-wds">
								<span>{{item*92}}</span>
								<span>回答</span>
							</a>
						</div>
					</div>
				</div>
				<div class="" v-if="essaya==1">
					<!-- 问答-热门游记 选项卡内容 -->
					<div class="wenda-wdn" v-for="item in [1,2,4,5,6,7]" :key="item">
						<div class="">
							<p class="wenda-wdo">
								<a href="" class="wenda-wdoo">俄罗斯机场转机，机场内是否酒店</a>
							</p>
							<div class="wenda-wdp">发表于2014年11月12日 10:50</div>
							<div class="wenda-wdq">国际机票提前多长时间订最好</div>
							<div class="wenda-wdq">{{item}}</div>
						</div>
						<div class="wenda-wdr">
							<a href="" class="wenda-wds">
								<span>{{item*9}}</span>
								<span>回答</span>
							</a>
						</div>
					</div>
				</div>
				<div class="" v-if="essaya==2">
					<!-- 问答-精品游记 选项卡内容 -->
					<div class="wenda-wdn" v-for="item in [1,2,4,5,6,7]" :key="item">
						<div class="">
							<p class="wenda-wdo">
								<a href="" class="wenda-wdoo">十一月</a>
							</p>
							<div class="wenda-wdp">发表于2014年11月12日 10:50</div>
							<div class="wenda-wdq">十一月</div>
							<div class="wenda-wdq">{{item}}</div>
						</div>
						<div class="wenda-wdr">
							<a href="" class="wenda-wds">
								<span>{{item*45}}</span>
								<span>回答</span>
							</a>
						</div>
					</div>
				</div>
				<div class="wenda-fenye">
					<!-- 分页 -->
					<el-pagination background layout="prev, pager, next" :total="100"></el-pagination>
				</div>
			</div>

			<div class="wenda-wdh">
				<!-- 问答右边-活跃旅行顾问 头-->
				<div class="wenda-wdt">活跃旅行顾问</div>
				<div class="wenda-wdu" v-for="item in [1,2,3,4,5,6,7,8,9]" :key="item">
					<div class="wenda-wdv">
						{{item}}
					</div>
					<div class="wenda-img">
						<el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
						</el-avatar>
					</div>
					<div class="wenda-wdw">
						<div class="wenda-wdx">
							<a href="" class="wenda-wdy">王楚钦</a>
						</div>
						<div class="wenda-wdz">
							<div class="wenda-wdzz">回答总数:</div>
							<div class="">{{item*67}}</div>
						</div>
					</div>

					<div class="wenda-hover">
						<!-- 鼠标移入顾问后显示弹框 头-->
						<div class="wenda-hovera">
							<div class="wenda-xinxin">
								<el-avatar :size="90"
									src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
									class="wenda-xinxinc">
								</el-avatar>
								<div class="wenda-xinxina">
									<img src="../../assets/xinxin.png">
									<div class="wenda-xinxinb">4.85</div>
								</div>
							</div>

							<div class="wenda-hoverb">
								<div class="wenda-hoverc">
									<div class="wenda-hoverd">
										<a href="">王楚钦</a>
									</div>
									<div class="wenda-hoveri">
										<div class="wenda-hoverimg">
											<img src="../../assets/nvxing.png" class="wenda-hoverimga">
											<!-- <img src="../../assets/nanxing.png" class="wenda-hoverimga"> -->
										</div>
										<div class="wenda-hovere" title="一起飞®国际旅行顾问">一起飞®国际旅行顾问</div>
									</div>
								</div>
								<div class="wenda-hoverf">
									<div class="wenda-hoverg">精通领域:</div>
									<div class="wenda-hoverh" title="欧洲 北美洲 东南亚 大洋洲 ">
										欧洲&nbsp;北美洲&nbsp;东南亚&nbsp;大洋洲&nbsp;</div>
								</div>
							</div>
						</div>

						<div class="wenda-hoverj">
							<div class="wenda-hoverk">
								<div class="wenda-hovero">
									<div class="">手机号:</div>
									<div class="wenda-hoverp">134*******5</div>
								</div>
								<div class="wenda-hovero">
									<div class="">电子邮箱:</div>
									<div class="wenda-hoverp">Kevin_liang@yiqifei.cn</div>
								</div>
							</div>
							<div class="wenda-erwei">
								<img src="http://t.yqf.cn/qr/lovhxU?level=M&backcolor=%23fff&forecolor=%23000&blockpixel=8&marginblock=1&logo=1"
									class="wenda-ewm">
							</div>
						</div>

						<div class="wenda-ta">
							<div class="wenda-taa">
								<a href="">在线向TA提问</a>
							</div>
							<div class="wenda-tab">
								<a href="" class="wenda-tab wenda-tac">
									<img src="../../assets/jiahao.png" class="wenda-jia">
									<div class="">加关注</div>
								</a>
								<!-- <div class="wenda-guanz">
									<div class="wenda-guanza">
										<a href="" class="wenda-tab wenda-tac">
											<img src="../../assets/dagou.png" class="wenda-jia">
											<div class="">已关注</div>
										</a>
									</div>
									<div class="wenda-guanzb">
										<a href="" class="wenda-tab wenda-tac">
											<img src="../../assets/shanchub.png" class="wenda-jia">
											<div class="">取消关注</div>
										</a>
									</div>
								</div> -->
								<div class="wenda-tab wenda-tad">
									<img src="../../assets/lianxi.png" class="wenda-lx">
									<div class="">QQ</div>
									<div class="">咨询</div>
								</div>
							</div>
							<div class="wenda-sanjiao"></div>
						</div>
						<!-- 鼠标移入顾问后显示弹框 尾-->
					</div>
				</div>
				<!-- 问答右边-活跃旅行顾问 尾-->
			</div>
		</div>


	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var staticData = require('@/data/staticData.js');
	//接口	
	var wqgApi = require('@/api/wqgApi.js');
	//组件引用

	//公共属性
	var that;

	export default {
		components: {
			//组件

		},
		data() {
			return {
				id: '',
				title: "社区首页",
				wend: '',
				input3: '',
				essaya: 0,
				essay: [{
						answers: '最新发表',
						answer: '|'
					},
					{
						answers: '热门游记',
						answer: '|'
					},
					{
						answers: '精品游记'
					}
				]
			};
		},
		created() {
			that = this;
		},
		methods: {
			btn(index) {
				// 问答-最新发表 热门游记 精品游记选项卡事件
				this.essaya = index
				console.log(index)
			}
		}
	};
	window.onscroll = function() {
		// 搜索框下拉定位到头部
		//scrollTop是浏览器滚动条的top位置，
		var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
		//通过判断滚动条的top位置与可视网页之和与整个网页的高度来返回各项参数
		// console.log(scrollTop)
		if (scrollTop >= 170) {
			that.wend = 'wenda-wdaa'
		} else {
			that.wend = ''
		}
	}
</script>

<style lang="scss">
	@import '../../style/youji/wenda.scss';
</style>
